import request from "@/utils/request";

/**
 * @return Promise
 */
export function getCaddieFeeInfoList(caddieArgmtDiv, caddieFeeDiv) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/caddiefee/info/list",
    method: "get",
    params: {
      caddieArgmtDiv,
      caddieFeeDiv,
    },
  });
}

/**
 * @return Promise
 */
export function updateCaddieFeeInfoList(updateCaddiefeeInfoList) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/caddiefee/info/list",
    method: "put",
    data: {
      updatedRecords: updateCaddiefeeInfoList,
    },
  });
}
