<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">캐디배치구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  cssClass="lookup-condition-dropdown"
                  ref="searchConditionsCaddieArgmtDiv"
                  v-bind="searchConditionsCaddieArgmtDivProps"
                  v-model="searchConditions.caddieArgmtDiv"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">캐디피구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  cssClass="lookup-condition-dropdown"
                  ref="searchConditionsCaddieFeeDiv"
                  v-bind="searchConditionsCaddieFeeDivProps"
                  v-model="searchConditions.caddieFeeDiv"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              @click.native="handleSearchClick"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <div class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">캐디피 요금 단가 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button" style="margin-right: 140px">
                <li class="save keyColor">
                  <erp-button
                      button-div="SAVE"
                      :is-key-color="true"
                      :is-shortcut-button="true"
                      @click.native="handleSaveClick"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
              <div class="header-switch">
                <div class="title">
                  멀티 편집모드
                </div>
                <div class="switch">
                  <ejs-switch
                    v-model="isModifyMode"
                    :checked="isModifyMode"
                    @change="onModifySwitchChanged"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
              ref="caddieFeePriceRegistrationGrid"
              v-bind="caddieFeePriceRegistrationGridProps"
              :selectionSettings="selectionSettings"
              :isSelectedRowRetain="true"
              :allowFiltering="!isModifyMode"
              :allowSorting="!isModifyMode"
              :isAutoSelectCell="!isModifyMode"
              @headerCellInfo="gridHeaderCellInfo"
              @queryCellInfo="gridQueryCellInfo"
              @cellSaved="handleCellSaved"
              @actionComplete="caddieFeePriceRegistrationGridActionComplete"
              @cellSelected="cellSelected"
            />
          </div>
        </section>
      </div>
    </div>
    <edit-multiple-columns-popup
      v-if="isEditMultipleColumnsPopupOpen"
      ref="editMultipleColumnsPopup"
      @popupConfirm="onEditMultipleColumnsPopupConfirm"
      @popupClosed="onEditMultipleColumnsPopupClose"
    />
    <edit-multiple-number-popup
      v-if="isEditMultipleNumberPopupOpen"
      ref="editMultipleNumberPopup"
      @popupConfirm="onEditMultipleNumberPopupConfirm"
      @popupClosed="onEditMultipleNumberPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field .content .item {width: 120px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import {
  getCaddieFeeInfoList,
  updateCaddieFeeInfoList,
} from "@/api/caddieFeePriceRegistration";
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  Edit,
  ForeignKey,
  Resize,
  Page,
} from "@syncfusion/ej2-vue-grids";
import { mapValues as _mapValues, findIndex as _findIndex, flatMap as _flatMap } from "lodash";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import editMultipleColumnsPopup from '@/views/common/EditMultipleColumnsPopup';
import editMultipleNumberPopup from '@/views/common/EditMultipleNumberPopup';
import ErpButton from "@/components/button/ErpButton";

const calcHoleDivWeight = holeDiv => {
  if (!(holeDiv.indexOf("_") < 0)) {
    holeDiv = holeDiv.split("_")[1];
  }
  return holeDiv.indexOf("+") < 0
    ? Number(holeDiv)
    : Number(holeDiv.replace("+", ""));
};

export default {
  name: "CaddieFeePriceRegistration",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    editMultipleColumnsPopup,
    editMultipleNumberPopup,
    ErpButton,
  },
  async created() {
    await this.fetch();
  },
  data() {
    return {
      count: 0,
      caddieFeeInfoListLength: 0,
      searchConditions: {
        caddieArgmtDiv: "",
        caddieFeeDiv: "",
      },
      caddieFeeInfoList: [],
      holeDivList: [], // order info
      selectionSettings: {
        enableToggle: false,
        mode: "Both",
        type: "Multiple",
      },
      isEditMultipleColumnsPopupOpen: false,
      isEditMultipleNumberPopupOpen: false,
      isModifyMode: false,
    };
  },
  computed: {
    searchConditionsCaddieArgmtDivProps() {
      const dataSource = commonCodesGetCommonCode("CADDIE_ARGMT_DIV");
      dataSource.unshift({
        comCode: "",
        comName: "전체",
      });

      return {
        allowFiltering: false,
        dataSource,
        fields: { text: "comName", value: "comCode" },
      };
    },
    searchConditionsCaddieFeeDivProps() {
      const dataSource = commonCodesGetCommonCode("CADDIE_FEE_DIV");
      dataSource.unshift({
        comCode: "",
        comName: "전체",
      });

      return {
        allowFiltering: false,
        dataSource,
        fields: { text: "comName", value: "comCode" },
      };
    },
    caddieFeePriceRegistrationGridProps() {
      const dataSource = this.caddieFeeInfoList.map(
        (
          { caddieArgmtDiv, caddieFeeDiv, caddieBagDiv, holeArrayList },
          index
        ) => {
          const caddieFeePrices = _mapValues(
            holeArrayList.reduce((accumulator, currentValue) => {
              const el = {};
              for (const key of Object.getOwnPropertyNames(currentValue)) {
                el[`hole_${key}`] = currentValue[key];
              }

              return {
                ...accumulator,
                ...el,
              };
            }, {}), // { "hole_1":, "120000.000", "hole_2": "130000.000", ... "hole_18+": "160000.000" }
            (value) => Number(`${value}`.replace(/,/gi, ""))
          );
          return {
            _rid: index + 1,
            caddieArgmtDiv,
            caddieFeeDiv,
            caddieBagDiv,
            ...caddieFeePrices,
          };
        }
      );
      return {
        provides: [Edit, ForeignKey, Resize, Page],
        isSelectedRowRetain: false,
        columns: [
          {
            allowEditing: false,
            field: "_rid",
            visible: false,
            isPrimaryKey: true,
          },
          {
            allowEditing: false,
            groupCode: "CADDIE_ARGMT_DIV",
            field: "caddieArgmtDiv",
            isCommonCodeField: true,
            headerText: "캐디배치구분",
            minWidth: 16,
            textAlign: "Left",
            type: "string",
            width: 120,
          },
          {
            allowEditing: false,
            groupCode: "CADDIE_FEE_DIV",
            field: "caddieFeeDiv",
            isCommonCodeField: true,
            headerText: "캐디피구분",
            minWidth: 16,
            textAlign: "Left",
            type: "string",
            width: 105,
          },
          {
            allowEditing: false,
            groupCode: "CADDIE_BAG_DIV",
            field: "caddieBagDiv",
            isCommonCodeField: true,
            headerText: "캐디백 수",
            minWidth: 16,
            textAlign: "Left",
            type: "string",
            width: 90,
          },
          {
            columns: this.holeDivList.map(({ holeDiv }, index) => {
              const _class = [this.$t("className.grid.modifyArea")];
              if (index === 0) {
                _class.push(this.$t("className.grid.requiredInputHeader"));
              }
              return {
                allowEditing: true,
                allowFiltering: false,
                customAttributes: { class: _class },
                field: `hole_${holeDiv}`,
                headerText: commonCodesGetComName("HOLE_DIV", holeDiv),
                textAlign: "Right",
                type: "string",
                isHorizontalEdit: true,
                isNumericType: true,
                width: 60,
              };
            }),
            headerText: "홀 구분",
          },
        ],
        dataSource,
        allowPaging: true,
        pageSettings: { pageSize: 50 },
      };
    },
  },
  methods: {
    numberWithCommas,
    // event handlers
    async handleSearchClick() {
      if (this.isThereUpdateRows()) {
        const confirmed = await this.confirm(
          "수정 내역이 있습니다. 진행하시겠습니까?"
        );

        if (!confirmed) {
          return;
        }
      }

      await this.fetch();
    },
    async handleSaveClick() {
      await this.save();
    },
    gridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field,
            headerText,
          },
        },
        node,
      } = args;
      if (this.isModifyMode && headerText !== 'NO') {
        let columns = [];
        this.caddieFeePriceRegistrationGridProps.columns
          ?.forEach(column => {
            if (column?.columns) {
              column.columns
                ?.forEach(c => columns.push(c));
            } else {
              columns.push(column);
            }
          });
        const allowedEditColumns = columns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field);
        if (
          allowedEditColumns.includes(field) ||
          headerText === '홀 구분'
        ) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          if (headerText !== '홀 구분') {
            node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column), false);
          }
        }
      }
    },
    async onGridHeaderClicked(column) {
      if (!column?.field) {
        return;
      }
      const gridRefs = this.$refs.caddieFeePriceRegistrationGrid;
      const selectedRowIndexes = [...new Array(gridRefs?.getBatchCurrentViewRecords().length).keys()];
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
    gridQueryCellInfo(args) {
      const {
        column: {field, headerText},
        cell,
      } = args;
      if (
        !this.isModifyMode &&
        (headerText === "18홀" || headerText === "09홀")
      ) {
        cell.style.backgroundColor = "rgba(46, 116, 88, .1)";
      }
      if (this.isModifyMode) {
        let columns = [];
        this.caddieFeePriceRegistrationGridProps.columns
          ?.forEach(column => {
            if (column?.columns) {
              column.columns
                ?.forEach(c => columns.push(c));
            } else {
              columns.push(column);
            }
          });
        const allowedEditColumns = columns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field);
        if (!allowedEditColumns.includes(field)) {
          cell.style.backgroundColor = '#f9f9f9';
          cell.style.pointerEvents = 'none';
        }
      }
    },
    handleCellSaved(args) {
      const {
        columnName,
        rowData: { _rid },
        previousValue,
        value,
      } = args;

      if (columnName.startsWith("hole_")) {
        const _previousValue = Number(`${previousValue}`.replace(/,/gi, ""));
        const _value = Number(`${value}`.replace(/,/gi, ""));
        if (_previousValue === _value) {
          return;
        }

        const gridRef = this.$refs["caddieFeePriceRegistrationGrid"];
        const batchCurrentViewRecords = gridRef.getBatchCurrentViewRecords();

        const weight = calcHoleDivWeight(columnName);
        if (
          columnName.indexOf("+") < 0 &&
          !(weight < 18) &&
          weight % 9 === 0 &&
          window.confirm("나머지 홀도 적용하시겠습니까?")
        ) {
          const rowIndex = _findIndex(
            batchCurrentViewRecords,
            ({ _rid: __rid }) => _rid === __rid
          );
          const data = {
            ...batchCurrentViewRecords[rowIndex],
          };

          const from = 9 * (weight / 9 - 2);
          const to = 9 * (weight / 9 - 1);

          this.holeDivList
            .filter(
              ({ holeDiv }) =>
                columnName !== holeDiv && !(weight < calcHoleDivWeight(holeDiv))
            )
            .forEach(({ holeDiv }) => {
              const _weight = calcHoleDivWeight(holeDiv);
              data[`hole_${holeDiv}`] =
                from < _weight && _weight <= to
                  ? Math.ceil(_value / 2)
                  : _value;
            });

          gridRef.updateRow(rowIndex, data);
        }
      }
    },

    // features
    isThereUpdateRows() {
      const { changedRecords } = this.$refs[
        "caddieFeePriceRegistrationGrid"
      ].getBatchChanges();

      return changedRecords.length !== 0;
    },

    // API
    async fetch() {
      const { caddieArgmtDiv, caddieFeeDiv } = this.searchConditions;
      const {
        value: { caddieFeeInfoList, holeDivList },
      } = await getCaddieFeeInfoList(
          caddieArgmtDiv || undefined,
          caddieFeeDiv || undefined
      );

      this.caddieFeeInfoList = caddieFeeInfoList;
      this.holeDivList = holeDivList;
      this.caddieFeeInfoListLength = this.caddieFeeInfoList.length;
    },
    async save() {
      const gridInstance = this.$refs["caddieFeePriceRegistrationGrid"];
      const { changedRecords } = gridInstance.getBatchChanges();
      const originRecords = gridInstance.getCurrentViewRecords();
      const updateRows = _flatMap(changedRecords, (r) => {
        const { caddieArgmtDiv, caddieFeeDiv, caddieBagDiv } = r;
        const findRecord = originRecords.find(
          ({
            caddieArgmtDiv: _caddieArgmtDiv,
            caddieFeeDiv: _caddieFeeDiv,
            caddieBagDiv: _caddieBagDiv,
          }) => {
            return (
              caddieArgmtDiv === _caddieArgmtDiv &&
              caddieFeeDiv === _caddieFeeDiv &&
              caddieBagDiv === _caddieBagDiv
            );
          }
        );
        return this.holeDivList
          .map(({ holeDiv }) => {
            return {
              caddieArgmtDiv,
              caddieFeeDiv,
              caddieBagDiv,
              holeDiv,
              caddieFeePrice: Number(
                  `${
                    findRecord &&
                    findRecord[`hole_${holeDiv}`] !== r[`hole_${holeDiv}`]
                      ? r[`hole_${holeDiv}`]
                      : undefined
                  }`.replace(/,/gi, "")
              ),
            };
          })
          .filter(
            ({ caddieFeePrice }) => caddieFeePrice || caddieFeePrice === 0
          );
      });
      await updateCaddieFeeInfoList(updateRows);
      await this.fetch();
      this.infoToast(this.$t("main.popupMessage.saved"));
    },
    caddieFeePriceRegistrationGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.caddieFeePriceRegistrationGrid?.getGridBatchCount() || 0
      );
    },
    onClickExcel() {
      this.$refs.caddieFeePriceRegistrationGrid.excelExport();
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.caddieFeePriceRegistrationGrid.updateCell(
          index,
          field,
          value,
        );
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },
    onEditMultipleNumberPopupConfirm(args) {
      const {
        fields,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleNumberPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      for (const index of rowIndexes) {
        for (const field of fields) {
          this.$refs.caddieFeePriceRegistrationGrid.updateCell(
            index,
            field,
            value,
          );
        }
      }
    },
    onEditMultipleNumberPopupClose() {
      this.isEditMultipleNumberPopupOpen = false;
    },
    onModifySwitchChanged(args) {
      const isChecked = args.checked;
      this.selectionSettings = isChecked
        ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
        : {enableToggle: false, mode: 'Both', type: 'Multiple'};
      this.$refs.caddieFeePriceRegistrationGrid.refresh();
      if (isChecked) {
        this.infoToast('멀티 편집할 셀 하나와 여러 로우를 드래그하세요.');
      }
    },
    async cellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const {
        cellIndex: {cellIndex},
        selectedRowCellIndex
      } = args;
      const cellIndexes = selectedRowCellIndex[0]?.cellIndexes;
      let columns = [];
      this.caddieFeePriceRegistrationGridProps.columns
        ?.forEach(column => {
          if (column?.columns) {
            column.columns
              ?.forEach(c => columns.push(c));
          } else {
            columns.push(column);
          }
        });
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      if (selectedRowIndexes.length < 1) {
        return;
      }
      if (cellIndexes.length > 1) {
        const allowedEditColumns = columns
          ?.filter(item => item.allowEditing && item.isHorizontalEdit)
          ?.map(item => item.field);
        let editColumns = [];
        cellIndexes?.forEach(c => editColumns.push(columns[c - 1]?.field));
        const hasNotHorizontalEdit = editColumns
          ?.filter(field => !allowedEditColumns?.includes(field));
        if (hasNotHorizontalEdit.length > 0) {
          this.errorToast('멀티 편집할 수 없는 컬럼이 포함되어 있습니다.');
          return;
        }
        this.isEditMultipleNumberPopupOpen = true;
        await this.$nextTick();
        this.$refs.editMultipleNumberPopup.showPopup(
          editColumns,
          selectedRowIndexes
        );
      } else {
        const allowedEditColumns = columns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field);
        const column = columns[cellIndex - 1];
        if (!allowedEditColumns.includes(column?.field)) {
          this.errorToast('편집할 수 있는 셀이 아닙니다.');
          return;
        }
        this.isEditMultipleColumnsPopupOpen = true;
        await this.$nextTick();
        this.$refs.editMultipleColumnsPopup.showPopup(
          column,
          selectedRowIndexes
        );
      }
    },
  },
};
</script>
